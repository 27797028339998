import React, { useRef, useEffect } from 'react';
import Navbar from "../components/Navbar";
import Hero from "../components/Hero";
import AboutImg from "../assets/imagemContacts";
import Footer from "../components/Footer";
import { SendMessage } from "../components/SendMessage";
import { ContactInfo } from "../components/ContactInfo";

function Contact() {
  const sendMessageRef = useRef(null);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <>
      <Navbar />
      <Hero cName="hero-mid" heroImg={AboutImg} title="Contactos" btnClass="hide" titleClass="page-title"/>
      <ContactInfo scrollToRef={sendMessageRef} />
      <SendMessage ref={sendMessageRef} />
      <Footer />
    </>
  );
}

export default Contact;
