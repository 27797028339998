import Image1 from "../assets/imagem1.avif";
import Image2 from "../assets/imagem2.avif";
import Image3 from "../assets/imagem3.avif";
import Image4 from "../assets/imagem4.avif";
import Image5 from "../assets/imagem5.avif";
import Image6 from "../assets/imagem6.avif";
import Image7 from "../assets/imagem7.avif";
import Image8 from "../assets/imagem8.avif";
import AquaFalls1 from "../assets/Aqua 1.jpeg";
import AquaFalls2 from "../assets/Aqua 2.jpeg";
import AquaFalls3 from "../assets/Aqua 3.jpeg";
import AquaFalls4 from "../assets/Aqua 4.jpeg";
import AquaFalls5 from "../assets/Aqua 5.jpeg";
import Armazem1 from "../assets/Armazem1.png";
import Armazem2 from "../assets/Armazem2.png";
import Armazem3 from "../assets/Armazem3.png";
import Armazem4 from "../assets/Armazem4.png";
import Armazem5 from "../assets/Armazem5.png";
import House1 from "../assets/House 1.png";
import House2 from "../assets/House 2.png";
import House3 from "../assets/House 3.png";
import House4 from "../assets/House 4.png";
import House5 from "../assets/House 5.png";
import Forte1 from "../assets/Forte Novo_1.png";
import Forte2 from "../assets/Forte Novo_2.png";
import Forte3 from "../assets/Forte Novo_3.png";
import Forte4 from "../assets/Forte Novo_4.png";
import Forte5 from "../assets/Forte Novo_5.jpg";
import Bivar1 from "../assets/Bivar_1.jpg";
import Bivar2 from "../assets/Bivar_2.jpg";
import Bivar3 from "../assets/Bivar_3.jpg";
import Bivar4 from "../assets/Bivar_4.jpg";
import Bivar5 from "../assets/Bivar_5.jpg";
import Cazanga1 from "../assets/Cazanga_1.jpeg";
import Cazanga2 from "../assets/Cazanga_2.jpg";
import Cazanga3 from "../assets/Cazanga_3.png";
import Cazanga4 from "../assets/Cazanga_4.jpg";
import Cazanga5 from "../assets/Cazanga_5.jpeg";
import CCCR1 from "../assets/CCCR_1.jpg";
import CCCR2 from "../assets/CCCR_2.jpg";
import CCCR3 from "../assets/CCCR_3jpg.jpg";
import CCCR4 from "../assets/CCCR_4.jpg";
import CCCR5 from "../assets/CCCR_5.jpeg";
import CAEPIESP1 from "../assets/CAEP IESP1.jpg";
import CAEPIESP2 from "../assets/CAEP IESP2.jpg";
import CAEPIESP3 from "../assets/CAEP IESP3.jpg";
import CAEPIESP4 from "../assets/CAEP IESP4.jpg";
import CAEPIESP5 from "../assets/CAEP IESP5.jpg";
import Almeirim1 from "../assets/Almeirim 3_1.jpg";
import Almeirim2 from "../assets/Almeirim 3_2.jpg";
import Almeirim15 from "../assets/Almeirim 15.jpg";
import Almeirim15A from "../assets/Almeirim 15A.jpg";
import Grocenter1 from "../assets/Grocenter Albergaria_1.png";
import Grocenter2 from "../assets/Grocenter Albergaria_2.png";
import Grocenter3 from "../assets/Grocenter Albergaria_3.png";
import Grocenter4 from "../assets/Grocenter Albergaria_4.jpg";
import GrocenterF1 from "../assets/Grocenter Famalicao_1.png";
import GrocenterF2 from "../assets/Grocenter Famalicao.png";
import GrocenterF3 from "../assets/Grocenter Famalicao_3.jpg";
import GrocenterF4 from "../assets/Grocenter Famalicao_4.png";
import GrocenterF5 from "../assets/Grocenter Famalicao_5.jpg";
import TheOne1 from "../assets/TheOne1.jpeg";
import TheOne2 from "../assets/TheOne2.jpeg";
import TheOne3 from "../assets/TheOne3.png";
import TheOne4 from "../assets/TheOne4.png";
import TheOne5 from "../assets/TheOne5.jpeg";
import Palmares1 from "../assets/Palmares_1.jpg";
import Palmares2 from "../assets/Palmares_2.png";
import Palmares3 from "../assets/Palmares_3.png";
import Palmares4 from "../assets/Palmares_4.png";
import Palmares5 from "../assets/Palmares_5.png";
import Distrikt1 from "../assets/Distrikt_1.png";
import Distrikt2 from "../assets/Distrikt_2.png";
import Distrikt3 from "../assets/Distrikt_3.jpg";
import Distrikt4 from "../assets/Distrikt_4.png";
import Distrikt5 from "../assets/Distrikt_5.png";
import Melulia1 from "../assets/Hotel Melulia Famalicao_1.jpeg";
import Melulia2 from "../assets/Hotel Melulia Famalicao_2.jpeg";
import Leonor1 from "../assets/D.Leonor_1.jpg";
import Leonor2 from "../assets/D.Leonor_2.jpg";
import Leonor3 from "../assets/D.Leonor_3.jpg";
import Leonor4 from "../assets/D.Leonor_4.jpg";
import Leonor5 from "../assets/D.Leonor_5.jpg";
import ANMP1 from "../assets/ANMP_0.jpeg";
import ANMP2 from "../assets/ANMP_1.jpeg";
import ANMP3 from "../assets/ANMP_2.jpeg";
import ANMP4 from "../assets/ANMP_3.jpeg";
import Alameda1 from "../assets/Alameda 303_1.jpeg";
import Alameda2 from "../assets/Alameda 303_2.jpeg";
import Alameda3 from "../assets/Alameda 303_3.jpeg";
import Alameda4 from "../assets/Alameda 303_4.jpeg";
import Plaza1 from "../assets/Plaza_1.jpg";
import Plaza2 from "../assets/Plaza_2.jpg";
import Plaza3 from "../assets/Plaza_3.jpg";
import Plaza4 from "../assets/Plaza_4.jpg";
import Plaza5 from "../assets/Plaza_5.jpg";
import Macau1 from "../assets/Macau_1.jpeg";
import Macau2 from "../assets/Macau_2.jpeg";
import Macau3 from "../assets/Macau_3.jpg";
import Macau4 from "../assets/Macau_4.jpg";
import Macau5 from "../assets/Macau_5.jpg";
import Arcadia1 from "../assets/Arcadia_1.jpeg";
import Arcadia2 from "../assets/Arcadia_2.jpg";
import Arcadia3 from "../assets/Arcadia_3.jpeg";
import Arcadia4 from "../assets/Arcadia_4.jpeg";
import Arcadia5 from "../assets/Arcadia_5.jpg";
import Hotel1 from "../assets/Hotel.png";
import Estabilidade from "../assets/Estabilidade1.avif";
import AquaAvif from "../assets/Aqua-1.avif";
import LeonorAvif from "../assets/D.Leonor_1.avif";
import AlmeirimAvif from "../assets/Almeirim-3_1.avif";
import MacauAvif from "../assets/Macau_1.avif";
import PalmaresAvif from "../assets/Palmares_1.avif";






export const topics = [
  {
    title: "Centrais de Produção",
    image: AlmeirimAvif,
    projects: [
      {
        client: "Central Solar Fotovoltaica 3,5 MWp - Santarém",
        image: Almeirim1,
        description:
          "Projeto de central solar fotovoltaica com potência de 3,5 MWp, cuja construção se insere na zona sul do país, no distrito de Santarém. A área ocupada pela central fotovoltaica ronda os 5,5 hectares. É composta por várias unidades de pequena produção, cada uma de 1,0 MVA, e ligada a Posto de Seccionamento do distribuidor de energia. Utiliza tecnologia fotovoltaica bifacial. Toda a central é monitorizada e gerida por sistema scada.",
        collaborative: false,
        images: [Almeirim1, Almeirim2]
      },
      {
        client: "Central Solar Fotovoltaica 17,4 MWp - Santarém",
        image: Almeirim15,
        description:
          "Projeto de central solar fotovoltaica com potência de 17,4 MWp, cuja construção se insere na zona sul do país, no distrito de Santarém. A área ocupada pela central fotovoltaica ronda os 31 km2. É composta por várias unidades de pequena produção, cada uma de 1,0 MVA, e ligada a Postos de Seccionamento do distribuidor de energia. Utiliza tecnologia fotovoltaica bifacial e sistema de seguidores solares. Toda a central é monitorizada e gerida por sistema scada.",
        collaborative: false,
        images: [Almeirim15, Almeirim15A]
      },
      {
        client: "Centro Grossista - Albergaria",
        image: Grocenter1,
        description:
          "Situado na freguesia de Albergaria-a-Velha, numa das zonas industriais principais do concelho de Aveiro, este centro comercial grossista é uma referência nacional para revendedores. Foi projetado uma Unidade de Produção para Autoconsumo de 872 kWp, incluindo Carport, numa área de 3000m2, constituída por painéis JÁ SOLAR, Tier I, monocristalino e inversores Huawei. Prevê-se uma produção de energia elétrica com possível impacto para 861 famílias, assim como diminuição de 315 toneladas equivalentes de CO2. A percentagem do consumo de energia será suprida pelo sistema produtor em cerca de 59%.",
        collaborative: false,
        images: [Grocenter1, Grocenter2, Grocenter3, Grocenter4]
      },
      {
        client: "Centro Grossista - Famalicão",
        image: GrocenterF1,
        description:
          "Situado na freguesia de Vilarinho das Cambas, numa das principais áreas industriais do concelho de Famalicão, este centro comercial grossista é uma referência nacional para revendedores. Num espaço com 7500 m2 de área coberta, 143 empresas fazem venda direta a comerciantes de uma grande variedade de produtos, contando com representações de Norte a Sul do país e até internacionais. Para este edifício foi projetado uma Unidade de Produção para Autoconsumo de 309 kWp, incluindo Carport, constituída por painéis JÁ SOLAR, Tier I, monocristalino e inversores Huawei. Prevê-se uma produção de energia elétrica com possível impacto para 136 famílias, assim como diminuição de 105 toneladas equivalentes de CO2. A percentagem do consumo de energia será suprida pelo sistema produtor em cerca de 42%.",
        collaborative: false,
        images: [GrocenterF1, GrocenterF2, GrocenterF3, GrocenterF4, GrocenterF5]
      }
    ],
    moreProjects: []
  },
  /*{
    title: "Redes de Distribuição",
    image: Image2,
    projects: [
      {
        client: "Igemaci Engenharia, SA - Loteamento Soyo",
        image: Image4,
        description:
          "Distribuição de energia eléctrica em baixa tensão referente a um loteamento, Soyo II, com área total de 565 hectares divididos por 11 unidades. Foram elaboradas as unidades 9 (9a e 9b) e 10 (10a e 10b) com 105 hectares cada. Situado na cidade de Soyo no norte de Angola, o loteamento conta com zonas de uso residencial, profissional, hospitalares, parques infantis, e desportivas",
        collaborative: true,
        images: [Image4, Image5, Image6]
      }
    ],
    moreProjects: []
  },*/
  {
    title: "Instalações Elétricas",
    image: AquaAvif,
    projects: [
      {
        client:
          "Maia Houses, Maia",
        image: House1,
        description:
          "Realização de projeto de instalações de utilização de energia eléctrica em baixa tensão, instalações de telecomunicações e instalações de segurança referentes a uma moradia unifamiliar de tipologia T4+1, com área coberta de 554m2 implantada em terreno urbano de 2528m2 , constituida por 4 suites, zona de estar, sala de jantar, escritório, cozinha, sala de cinema privativa, piscina exterior, garagem e zonas técnicas, situada na Rua do Chantre, lote 11, Leça do Balio.",
        collaborative: false,
        images: [House1, House2, House3, House4, House5]
      },
      {
        client: "Armazém DivisionCare, Famalicão",
        image: Armazem1,
        description:
          "Projeto de instalações de utilização de energia eléctrica em baixa tensão, infraestruturas de telecomunicações e instalações de segurança de edifício industrial, com área coberta de 2183m2, situado na cidade de Famalicão e constituído por receção, sala de reuniões, auditório, gabinetes, refeitório, balneários, wc’s, 2 áreas de grande armazenamento e zonas técnicas.",
        collaborative: false,
        images: [Armazem1, Armazem2, Armazem3, Armazem4, Armazem5]
      },
      {
        client:
          "Condomínio Habitacional, Quarteira",
        image: Forte1,
        description:
          "Empreendimento habitacional localizado na Praia do Forte Novo, em Quarteira, envolvido pelo azul do oceano Atlântico e sob praias de areia douradas, servido pelo melhor da gastronomia, passadiços, marinas e campos de golfe, e a apenas 20 km do Aeroporto de Faro. O condomínio composto por 129 apartamentos, todos com varanda com vista para o mar algarvio, divide-se em dois lotes, ambos privilegiando o conforto e a natureza. O primeiro lote habitacional é composto por 67 apartamentos amplos e luminosos, de design contemporâneos e minimalista, distribuídos por 3 pisos virados para o mar, de tipologias T1 a T4 Penthouses. Os apartamentos do segundo complexo habitacional divide-se em 2 edifícios, de 3 e 6 pisos, composto por 62 modernas e exclusivas frações de tipologias T1 a T4 penthouses. ",
        collaborative: false,
        images: [Forte1, Forte2, Forte3, Forte4, Forte5]
      },
      {
        client:
          "Edifício Habitacional e Comercial, Lisboa",
        image: Bivar1,
        description: "O empreendimento beneficia de uma localização privilegiada no centro da cidade de Lisboa. Condomínio fechado Premium com 31 unidades de tipologias T1 a T4, com áreas entre os 55 e 222 m2, todos com estacionamento e arrecadação, localizada-se no centro das Avenidas Novas, entre o Saldanha e a Gulbenkian, resultado de uma reabilitação de dois edifícios. Alguns apartamentos contam com espaços exteriores e piscinas privativas sendo que a cobertura do condomínio conta com piscina comum.",
        collaborative: false,
        images: [Bivar1, Bivar2, Bivar3, Bivar4, Bivar5]
      },
      {
        client:
          "Complexo Turístico AquaFalls, Gerês",
        image: AquaFalls1,
        description:
          "Realização de projeto de instalações de utilização de energia eléctrica, incluindo posto de transformação, grupo eletrogéneo e infraestruturas de utilização de energia bem como das instalações de telecomunicações e instalações de segurança referente ao Complexo Turístico com SPA, AquaFalls. Empreendimento turístico de cinco estrela implantado numa área de construção de 26600m2 na encosta da serra virada ao Parque Natural do Gerês e para o Rio Cávado, junto à barragem da Caniçada. A edificação é constituída por um complexo central com piscina interior aquecida, piscina exterior, sauna, banho turco, fonte de gelo, duche de sensações, 6 gabinetes de terapia, espaços de relaxamento, ginásio, auditório, restaurante, bar e espaço comercial. Paralelamente ao edifício central, o empreendimento é composto por 22 suites e 2 quartos, distribuídos por 11 bangalows. No exterior, o complexo é completado pelo campo de ténis e pelo mini-golfe.",
        collaborative: false,
        images: [AquaFalls1, AquaFalls2, AquaFalls3, AquaFalls4, AquaFalls5]
      },
      {
        client: "Casa do Chocolate Arcádia - Boavista / Marshopping Matosinhos",
        image: Arcadia1,
        description:
          "Realização de projeto de instalações de utilização de energia eléctrica em baixa tensão, instalações de telecomunicações e instalações de segurança referentes a espaços de restauração destinados à venda de produtos de chocolate e serviços de alimentação, com áreas totais de 237m2 e 528m2, situados no Centro Comercial MarShopping (Matosinhos) e na Avenida da Boavista, respetivamente.",
        collaborative: false,
        images: [Arcadia1, Arcadia2, Arcadia3, Arcadia4, Arcadia5]
      }
    ],
    moreProjects: [
      {
        client:
          "Moradia T4+1 - Leça do Balio (2)",
        description:
          "Realização de projeto de instalações de utilização de energia eléctrica em baixa tensão, instalações de telecomunicações e instalações de segurança referentes a uma moradia unifamiliar de tipologia T4+1, com área coberta de 546m2 implantada em terreno urbano de 2597m2, constituida por 4 suites, zona de estar, sala de jantar, escritório, cozinha, sala de cinema privativa, piscina exterior, garagem e zonas técnicas, situada na Rua do Chantre, lote 10, Leça do Balio",
        collaborative: false
      },
      {
        client:
          "Moradia T4+1 - Leça do Balio (3)",
        description:
          "Realização de projeto de instalações de utilização de energia eléctrica em baixa tensão, instalações de telecomunicações e instalações de segurança referentes a uma moradia unifamiliar de tipologia T4+1, com área coberta de 596m2 implantada em terreno urbano de 2588m2, constituida por 4 suites, zona de estar, sala de jantar, escritório, cozinha, sala de cinema privativa, piscina exterior, garagem e zonas técnicas, situada na Rua do Chantre, lote 9, Leça do Balio.",
        collaborative: false
      },
      {
        client:
          "Loja de têxteis - Matosinhos",
        description:
          "Realização de projeto de instalações de utilização de energia eléctrica em baixa tensão, instalações de telecomunicações e instalações de segurança referentes a um espaço destinado ao comércio de por grosso de têxteis, com área total de 196m2, situado na Rua Sousa Aroso, Matosinhos.",
        collaborative: false
      },
      {
        client: "Loja de calçado - Barcelos",
        description:
          "Realização de projeto de instalações de utilização de energia eléctrica em baixa tensão, instalações de telecomunicações e instalações de segurança referentes a um espaço comercial destinado ao comércio de calçado e malas de marcas nacionais e internacionais, com 4 pisos , situado na Rua D.António Barroso, Barcelos",
        collaborative: false
      },
      {
        client:
          "Loja de Restauração - Lisboa",
        description:
          "Realização de projeto de instalações de utilização de energia eléctrica em baixa tensão, instalações de telecomunicações e instalações de segurança referentes a um espaço de restauração destinado à venda de produtos de chocolate e serviços de alimentação, com área total de 260m2, situado no piso 1 do Centro Comercial Dolce Vita Tejo, Lisboa.",
        collaborative: false
      },
      {
        client: "Loja de Restauração - Porto",
        description:
          "Projeto de instalações de utilização de energia eléctrica em baixa tensão, instalações de telecomunicações e instalações de segurança referentes a um espaço de restauração destinado à venda de produtos de chocolate e serviços de alimentação, com área total de 528m2, situado na Avenida da Boavista, Porto.",
        collaborative: false
      },
      {
        client: "Edifício de escritórios - Lisboa",
        description:
          "Realização de projeto de instalações de utilização de energia eléctrica em baixa tensão, instalações de telecomunicações e instalações de segurança referentes a um espaço de escritório destinado a atividades de gestão e planeamento, com área total de 348m2, situado na Rua Ivone Silva, Lisboa.",
        collaborative: false
      },
      {
        client: "Loja de têxteis - Lisboa",
        description:
          "Realização de projeto de instalações de utilização de energia eléctrica em baixa tensão, instalações de telecomunicações e instalações de segurança referentes a um espaço destinado ao comércio de por grosso de têxteis, com área total de 393m2, situado na Avenida 24 Julho, Lisboa",
        collaborative: false
      },
      {
        client: "Edifício de escritórios - Porto",
        description:
          "Realização de projeto de restauro das infraestruturas de instalações de utilização de energia eléctrica em baixa tensão e instalações de telecomunicações referentes a um espaço de escritório destinado à atividade bancária e gestão de património, com área total de 390m2.",
        collaborative: false
      },
      {
        client: "Cafetaria - LouresShopping",
        description:
          "Realização de projeto de instalações de utilização de energia eléctrica em baixa tensão e instalações de telecomunicações referentes a um espaço de restauração destinado a serviços de cafetaria e alimentação, com área total de 20m2, situado no Centro Comercial LouresShopping",
        collaborative: false
      },
      {
        client: "Moradia T5+1 - Boavista",
        description:
          "Realização de projeto de instalações de utilização de energia eléctrica em baixa tensão, instalações de telecomunicações e instalações de segurança referentes a uma moradia unifamiliar de tipologia T5+1, com área coberta de 884m2 implantada em terreno urbano de 1146m2, constituida por 5 suites, 1 suite dupla, zona de estar, sala de jantar, escritório, cozinha, piscina interior, banho turco, sauna, sala de cinema privativa, piscina exterior, garagem e zonas técnicas, situada na Avenida do Marechal Gomes da Costa, Boavista",
        collaborative: false
      },
      {
        client: "Ourivesaria - Porto",
        description:
          "Realização de projeto de instalações de utilização de energia eléctrica em baixa tensão, instalações de telecomunicações e instalações de segurança referentes a um espaço comercial destinado ao comércio de ourivesaria, joalharia e relojoaria, com área total de 49m2, situado no Centro Comercial Dolce Vita Porto.",
        collaborative: false
      },
      {
        client:
          "Cafetaria - Coimbra",
        description:
          "Realização de projeto de instalações de utilização de energia eléctrica em baixa tensão e instalações de telecomunicações referentes a um espaço de restauração destinado a serviços de cafetaria e alimentação, com área total de 15m2, situado no Centro Comercial Dolce Vita Coimbra.",
        collaborative: false
      },
      {
        client:
          "Complexo Habitacional - Viana do Castelo",
        description:
          "Realização de projeto de instalações de utilização de energia eléctrica em baixa tensão e instalações de telecomunicações referentes a um complexo habitacional com área de implantação de 280m2, situado no Lugar da Bessa, lote 2 em Viana do Castelo, constituído por 16 frações, 7 de tipologia T3, 5 de tipologia T2 e 4 de tipologia T1, distribuídas por sub-cave, cave, rés-do-chão, 5 pisos e sótão destinado a arrumos",
        collaborative: false
      },
      {
        client: "Complexo Habitacional - Matosinhos",
        description:
          "Realização de projeto de instalações de utilização de energia eléctrica em baixa tensão e instalações de telecomunicações referentes a um complexo habitacional com área de implantação de 255m2, situado no Lugar do Freixieiro, lote 3 em Matosinhos, constituído por 11 frações, 4 de tipologia T2 e 7 de tipologia T1, distribuídas por cave, rés-do-chão e 3 pisos.",
        collaborative: false
      },
      {
        client: "Moradia T4+1 - Leça do Balio",
        description:
          "Projeto de instalações de utilização de energia eléctrica em baixa tensão, instalações de telecomunicações e instalações de segurança referentes a uma moradia unifamiliar de tipologia T4+1, com área coberta de 503m2 implantada em terreno urbano de 2072m2, constituida por 4 suites, zona de estar, sala de jantar, escritório, cozinha, sala de cinema privativa, piscina exterior, garagem e zonas técnicas, situada na Rua do Chantre, lote 12, Leça do Balio.",
        collaborative: false
      },
      {
        client: "Complexo Hoteleiro - Luanda",
        description:
          "Realização de projeto de instalações de utilização de energia eléctrica em baixa tensão e instalações de telecomunicações referentes a um complexo hoteleiro, Guest Office Hotel, com área de 2142m2, situado na cidade de Luanda em Angola, desenvolvendo-se por 5 pisos acima do solo e 1 subterrâneo, constituído 12 suites, 6 salas de reuniões, gabinetes, piscina, ginásio, cabeleireiro, recepção, cozinha, sala de refeições, zonas técnicas e arrumos.",
        collaborative: false
      },
      {
        client: "Hospital - Angra do Heroísmo",
        description:
          "Realização da especialidade de instalações de iluminação normal referente ao Hospital de Angra do Heroísmo situado na cidade de Angra do Heroísmo na Ilha Terceira. Com área de implantação de 14640m2 e cerca 50000m2 de área coberta, é constituído por 4 pisos acima do solo e 1 de estacionamento subterrâneo com capacidade para 1000 veículos. Conta com 216 camas em quartos duplos de internamento geral e 25 quartos de internamento especial, 6 salas de bloco operatório central e 1 bloco operatório de obstetrícia além da vários consultórios, gabinetes, salas de apoio, entre outros espaços diversos.",
        collaborative: false
      },
      {
        client: "Moradia T4 - Oliveira de Azeméis",
        description:
          "Realização de projeto de instalações de utilização de energia eléctrica em baixa tensão, instalações de telecomunicações e instalações especiais referentes a uma moradia unifamiliar de tipologia T4, constituída por 4 quartos, 2 wc's, zona de estar/jantar, cozinha, garagem e arrumos, situada em Oliveira de Azeméis.",
        collaborative: false
      },
      {
        client: "Moradia T4 - Vale de Cambra",
        description:
          "Realização de projeto de instalações de utilização de energia eléctrica em baixa tensão, instalações de telecomunicações e instalações especiais referentes a uma moradia unifamiliar de tipologia T4, constituída por 4 suites completas, 1 wc de serviço, zona de estar, sala de jantar, jacuzzi, ginásio, cozinha, garagem e zona de arrumos, situada em São Pedro de Castelões, Vale de Cambra.",
        collaborative: false
      },
      {
        client: "Complexo Hoteleiro - Algarve",
        description:
          "Realização de projeto de instalações de segurança referentes a um complexo hoteleiro, Hotel Apartamentos Atalaia, com área coberta de 11816m2, situado no Algarve.",
        collaborative: false
      },
      {
        client: "Complexo Habitacional - Tavira",
        description:
          "Realização de projeto de instalações privadas de distribuição de energia eléctrica em baixa tensão ,instalações de utilização de energia eléctrica em baixa tensão, instalações de telecomunicações e arranjos exteriores referentes a um loteamento habitacional privado composto por 7 edifícios residenciais, com área de 278m2 por piso e 3 pisos, caracterizados por 4 habitações de tipologia T2 e 1 de tipologia T3, situado no Sítio de Canada em Tavira.",
        collaborative: false
      },
      {
        client: "Espaço Comercial - Albufeira",
        description:
          "Realização de projeto de instalações de utilização de energia eléctrica em baixa tensão, instalações de telecomunicações, instalações de segurança e instalação de som ambiente e chamada referentes a um espaço comercial com área aproximada de 1250m2, situado no Lugar da Tavagueira, Arm. E em Albufeira, constituído por zona de público, pós-venda, armazém, escritório, refeitório vestiários e wc's.",
        collaborative: false
      },
      {
        client: "Parque de Estacionamento Público - Tomar",
        description:
          "Realização de projeto de instalações de utilização de energia eléctrica em baixa tensão, instalações de telecomunicações e instalação de segurança referentes a um parque de estacionamento público com área de construção de 5352m2, situado na Praça da República em Tomar, constituído por 3 pisos com um total de 187 lugares de estacionamento.",
        collaborative: false
      },
      {
        client: "Espaço Comercial - Setúbal",
        description:
          "Realização de projeto de instalações de utilização de energia eléctrica em baixa tensão, instalações de telecomunicações e instalação de som ambiente e chamada referentes a um espaço comercial com área de 2008m2, situado Av. Mestre Lima Freitas, Arm. D em Setúbal, constituído por zona de público, pós-venda, armazém, escritório, refeitório vestiários e wc's.",
        collaborative: false
      },
      {
        client: "Hotel Rural - Covas do Douro",
        description:
          "Realização de projeto de instalações de utilização de energia eléctrica em baixa tensão, instalações de telecomunicações e instalação de som ambiente e chamada referentes a um hotel rural situado em Quinta Nova, Covas do Douro.",
        collaborative: false
      },
      {
        client: "Edifício Comercial - Porto",
        description:
          "Realização de projeto de instalações de utilização de energia eléctrica em baixa tensão e instalações de telecomunicações referentes a um edifício com r/chão, 1º, 2º e 3º andar, com área de habitável de 576m2, situado na Rua Costa Cabral no Porto, constituído por estúdio e laboratório de fotografia, biblioteca, gabinetes, salas de aula, salas de trabalhos multidisciplinares, sala de reuniões, arquivo e wc's.",
        collaborative: false
      },
      {
        client: "Espaço Comercial - Ponta Delgada",
        description:
          "Realização de projeto de instalações de utilização de energia eléctrica em baixa tensão, instalações de telecomunicações, instalações de segurança e instalação de som ambiente e chamada referentes a um espaço comercial com área de 1709m2, situado na Estrada São Gonçalo em Ponta Delgada, constituído por zona de público, pós-venda, armazém, escritório, refeitório vestiários e wc's.",
        collaborative: false
      },
      {
        client: "Complexo de Armazéns - Braga",
        description:
          "Realização de projeto de instalações de utilização de energia eléctrica em baixa tensão e instalações de telecomunicações referentes a 7 fracções contíguas de armazéns, com área de 630m2 por fracção, desenvolvendo-se por 3 pisos, situado em Ferreiros, Largo da Misericórdia, Braga, constituído por zona de armazém, comércio ou serviços, wc's e aparcamento interior privativo.",
        collaborative: false
      },
      {
        client: "Cinema - Bragança",
        description:
          "Realização de projeto de instalações de utilização de energia eléctrica em baixa tensão, instalações de telecomunicações e instalações de segurança referentes a um cinema com área de 1014m2, situado no Forum Theatrum, na Praça Cavaleiro de Ferreira em Bragança, constituído por 3 salas de cinema, cabine de projecção única, bar e bilheteira, zona de escritório e wc's.",
        collaborative: false
      },
      {
        client: "Moradia T4 - Paredes",
        description:
          "Realização de projeto de instalações de utilização de energia eléctrica em baixa tensão, instalações de telecomunicações e instalações especiais referentes a uma moradia unifamiliar de tipologia T4, constituída por 4quartos, 2 wc's, zona de estar, sala de jantar, escritório, cozinha e garagem, situada em Besteirós, Paredes",
        collaborative: false
      },
      {
        client: "Centro Comercial - Dolce Vita Porto",
        description:
          "Realização do projeto de distribuição de energia eléctrica em média e baixa tensão referente a um centro comercial, Centro Comercial Dolce Vita Porto, com área de 39000m2, situado na Rua dos Campeões Europeus, Porto, constituído por hipermercado, cinemas, cerca de 120 lojas e 30 restaurantes, 1700 lugares de estacionamento e wc's entre outros espaços.",
        collaborative: false
      },
      {
        client: "Espaço Comercial - Montijo",
        description:
          "Realização de projeto de instalações de utilização de energia eléctrica em baixa tensão, instalações de telecomunicações e instalação de som ambiente e chamada referentes a um espaço comercial com área de 2080m2, situado na Rua da Azinheira, Armazém D, Zona Industrial Pau Queimado no  Montijo, constituído por zona de público, pós-venda, armazém, escritório, refeitório vestiários e wc's.",
        collaborative: false
      }
    ]
  },
  
  {
    title: "Telecomunicações",
    image: PalmaresAvif,
    projects: [
      {
        client:
          "Complexo Habitacional e Comercial - Lisboa",
        image: TheOne1,
        description:
          "Empreendimento residencial exclusivo que se destaca no cenário da cidade. Localizado em uma área privilegiada, este edifício de luxo oferece uma arquitetura contemporânea e sofisticada. Com sua fachada de vidro reluzente, proporciona vistas panorâmicas deslumbrantes de Lisboa, incluindo o majestoso rio Tejo. O seu interior é igualmente impressionante, com apartamentos espaçosos e bem projetados que refletem o mais alto padrão de qualidade. As unidades podem ser equipadas com acabamentos de luxo, com atenção meticulosa aos detalhes. Muitos apartamentos desfrutam de amplas janelas que inundam os espaços com luz natural, criando uma atmosfera convidativa e arejada. Os moradores podem desfrutar de diversas comodidades, como áreas de lazer, piscinas, academias e espaços ao ar livre, proporcionando um ambiente de vida completo e exclusivo. É uma referência em residências de alto padrão em Lisboa, atraindo aqueles que procuram um estilo de vida luxuoso numa localização central e conveniente da cidade.",
        collaborative: false,
        images: [TheOne1, TheOne2, TheOne3, TheOne4, TheOne5]
      },
      {
        client:
          "Edifício Habitacional e Comercial - Lisboa",
        image: Bivar1,
        description:
          "O empreendimento beneficia de uma localização privilegiada no centro da cidade de Lisboa. Condomínio fechado Premium com 31 unidades de tipologias T1 a T4, com áreas entre os 55 e 222 m2, todos com estacionamento e arrecadação, localizada-se no centro das Avenidas Novas, entre o Saldanha e a Gulbenkian, resultado de uma reabilitação de dois edifícios. Alguns apartamentos contam com espaços exteriores e piscinas privativas sendo que a cobertura do condomínio conta com piscina comum.",
        collaborative: false,
        images: [Bivar1, Bivar2, Bivar3, Bivar4, Bivar5]
      },
      {
        client: "Apartamentos Turísticos em Resort - Lagos",
        image: Palmares1,
        description:
          "Um total de 81 apartamentos (37 na primeira fase e 44 na segunda fase), tipo moradia, dentro de complexo turístico que combina luxo, natureza e desporto. Localizado em uma região privilegiada da costa algarvia, oferece residências sofisticadas, campo de golfe de classe mundial e acesso a praias deslumbrantes. Com uma arquitetura moderna e integrada ao ambiente, o complexo turístico proporciona uma experiência única de estilo de vida à beira-mar, cercado por paisagens exuberantes. As instalações do empreendimento incluem amenidades de alto padrão, destacando-se como um destino premium para quem busca conforto e exclusividade.",
        collaborative: false,
        images: [Palmares1, Palmares2, Palmares3, Palmares4, Palmares5]
      },
      {
        client:
          "Condomínio Habitacional, Quarteira",
        image: Forte1,
        description:
          "Empreendimento habitacional localizado na Praia do Forte Novo, em Quarteira, envolvido pelo azul do oceano Atlântico e sob praias de areia douradas, servido pelo melhor da gastronomia, passadiços, marinas e campos de golfe, e a apenas 20 km do Aeroporto de Faro. O condomínio composto por 129 apartamentos, todos com varanda com vista para o mar algarvio, divide-se em dois lotes, ambos privilegiando o conforto e a natureza. O primeiro lote habitacional é composto por 67 apartamentos amplos e luminosos, de design contemporâneos e minimalista, distribuídos por 3 pisos virados para o mar, de tipologias T1 a T4 Penthouses. Os apartamentos do segundo complexo habitacional divide-se em 2 edifícios, de 3 e 6 pisos, composto por 62 modernas e exclusivas frações de tipologias T1 a T4 penthouses. ",
        collaborative: false,
        images: [Forte1, Forte2, Forte3, Forte4, Forte5]
      },
      {
        client: "Empreendimento Habitacional e Parque Público - Lisboa",
        image: Distrikt1,
        description:
          "Empreendimento habitacional de luxo, localizado em zona nobre da cidade de Lisboa, projetado com base no compromisso de criar espaços atrativos e funcionais, adaptados ao ambiente e à cidade. A adaptação da arquitetura ao ambiente e as formas dinâmicas e a escala do projeto enquadram-se perfeitamente na atmosfera futurista do Parque das Nações. Conta com ampla zona verde e de lazer, com piscinas e espaços ajardinados, numa plataforma elevada em relação à rua e vista para o rio Tejo, permitindo o desfrutar de paisagens extraordinárias.",
        collaborative: false,
        images: [Distrikt1, Distrikt2, Distrikt3, Distrikt4, Distrikt5]
      },
      {
        client: "Empreendimento Hoteleiro - Vila Nova de Famalicão",
        image: Melulia1,
        description:
          "Empreendimento hoteleiro, com 120 quartos, a edificar na cidade de Vila Nova de Famalicão. A unidade hoteleira enquadra-se com os restantes 7 edifícios habitacionais da urbanização “Seven Arts”, adotando materiais comuns aos restantes, do ponto de vista de fachada, mas utilizando predominantemente o vidro. Com uma excelente exposição solar e vistas para o Parque da Devesa, permite usufruir das valências que o parque oferece, num ambiente da máxima proximidade com a natureza. O complexo hoteleiro dispõe de ginásio, spa, piscinas (interior e exterior), bar, restaurante e várias salas para reuniões, eventos e conferências.",
        collaborative: false,
        images: [Melulia1, Melulia2]
      }
    ],
    moreProjects: [
      {
        client: "Moradia T4+1 - Leça do Balio",
        description:
          "Projeto de instalações de utilização de energia eléctrica em baixa tensão, instalações de telecomunicações e instalações de segurança referentes a uma moradia unifamiliar de tipologia T4+1, com área coberta de 503m2 implantada em terreno urbano de 2072m2, constituida por 4 suites, zona de estar, sala de jantar, escritório, cozinha, sala de cinema privativa, piscina exterior, garagem e zonas técnicas, situada na Rua do Chantre, lote 12, Leça do Balio.",
        collaborative: false
      },
      {
        client: "Moradia T4+1 - Leça do Balio (2)",
        description:
          "Realização de projeto de instalações de utilização de energia eléctrica em baixa tensão, instalações de telecomunicações e instalações de segurança referentes a uma moradia unifamiliar de tipologia T4+1, com área coberta de 546m2 implantada em terreno urbano de 2597m2, constituida por 4 suites, zona de estar, sala de jantar, escritório, cozinha, sala de cinema privativa, piscina exterior, garagem e zonas técnicas, situada na Rua do Chantre, lote 10, Leça do Balio",
        collaborative: false
      },
      {
        client: "Moradia T4+1 - Leça do Balio (3)",
        description:
          "Realização de projeto de instalações de utilização de energia eléctrica em baixa tensão, instalações de telecomunicações e instalações de segurança referentes a uma moradia unifamiliar de tipologia T4+1, com área coberta de 596m2 implantada em terreno urbano de 2588m2, constituida por 4 suites, zona de estar, sala de jantar, escritório, cozinha, sala de cinema privativa, piscina exterior, garagem e zonas técnicas, situada na Rua do Chantre, lote 9, Leça do Balio.",
        collaborative: false
      },
      {
        client: "Loja de Têxteis - Matosinhos",
        description:
          "Realização de projeto de instalações de utilização de energia eléctrica em baixa tensão, instalações de telecomunicações e instalações de segurança referentes a um espaço destinado ao comércio de por grosso de têxteis, com área total de 196m2, situado na Rua Sousa Aroso, Matosinhos.",
        collaborative: false
      },
      {
        client: "Loja de Calçado - Barcelos",
        description:
          "Realização de projeto de instalações de utilização de energia eléctrica em baixa tensão, instalações de telecomunicações e instalações de segurança referentes a um espaço comercial destinado ao comércio de calçado e malas de marcas nacionais e internacionais, com 4 pisos , situado na Rua D.António Barroso, Barcelos",
        collaborative: false
      },
      {
        client: "Espaço de Restauração - Lisboa",
        description:
          "Realização de projeto de instalações de utilização de energia eléctrica em baixa tensão, instalações de telecomunicações e instalações de segurança referentes a um espaço de restauração destinado à venda de produtos de chocolate e serviços de alimentação, com área total de 260m2, situado no piso 1 do Centro Comercial Dolce Vita Tejo, Lisboa.",
        collaborative: false
      },
      {
        client: "Espaço de Escritório - Lisboa",
        description:
          "Realização de projeto de instalações de utilização de energia eléctrica em baixa tensão, instalações de telecomunicações e instalações de segurança referentes a um espaço de escritório destinado a atividades de gestão e planeamento, com área total de 348m2, situado na Rua Ivone Silva, Lisboa.",
        collaborative: false
      },
      {
        client: "Espaço de Restauração - Lisboa (2)",
        description:
          "Realização de projeto de instalações de utilização de energia eléctrica em baixa tensão, instalações de telecomunicações e instalações de segurança referentes a um espaço destinado ao comércio de por grosso de têxteis, com área total de 393m2 , situado na Avenida 24 Julho, Lisboa.",
        collaborative: false
      },
      {
        client: "Espaço de Restauração - Porto ",
        description:
          "Projeto de instalações de utilização de energia eléctrica em baixa tensão, instalações de telecomunicações e instalações de segurança referentes a um espaço de restauração destinado à venda de produtos de chocolate e serviços de alimentação, com área total de 528m2, situado na Avenida da Boavista, Porto.",
        collaborative: false
      },
      {
        client: "Loja de Têxteis - Lisboa",
        description:
          "Realização de projeto de instalações de utilização de energia eléctrica em baixa tensão, instalações de telecomunicações e instalações de segurança referentes a um espaço destinado ao comércio de por grosso de têxteis, com área total de 393m2, situado na Avenida 24 Julho, Lisboa",
        collaborative: false
      },
      {
        client: "Espaço de Escritório - Porto",
        description:
          "Realização de projeto de restauro das infraestruturas de instalações de utilização de energia eléctrica em baixa tensão e instalações de telecomunicações referentes a um espaço de escritório destinado à atividade bancária e gestão de património, com área total de 390m2.",
        collaborative: false
      },
      {
        client: "Moradia T5+1, Boavista",
        description:
          "Realização de projeto de instalações de utilização de energia eléctrica em baixa tensão, instalações de telecomunicações e instalações de segurança referentes a uma moradia unifamiliar de tipologia T5+1, com área coberta de 884m2 implantada em terreno urbano de 1146m2, constituida por 5 suites, 1 suite dupla, zona de estar, sala de jantar, escritório, cozinha, piscina interior, banho turco, sauna, sala de cinema privativa, piscina exterior, garagem e zonas técnicas, situada na Avenida do Marechal Gomes da Costa, Boavista",
        collaborative: false
      },
      {
        client: "Espaço de Ourivesaria - Porto",
        description:
          "Realização de projeto de instalações de utilização de energia eléctrica em baixa tensão, instalações de telecomunicações e instalações de segurança referentes a um espaço comercial destinado ao comércio de ourivesaria, joalharia e relojoaria, com área total de 49m2, situado no Centro Comercial Dolce Vita Porto.",
        collaborative: false
      },
      {
        client: "Cafetaria - Coimbra",
        description:
          "Realização de projeto de instalações de utilização de energia eléctrica em baixa tensão e instalações de telecomunicações referentes a um espaço de restauração destinado a serviços de cafetaria e alimentação, com área total de 15m2, situado no Centro Comercial Dolce Vita Coimbra.",
        collaborative: false
      },
      {
        client: "Complexo Habitacional - Viana do Castelo",
        description:
          "Realização de projeto de instalações de utilização de energia eléctrica em baixa tensão e instalações de telecomunicações referentes a um complexo habitacional com área de implantação de 280m2, situado no Lugar da Bessa, lote 2 em Viana do Castelo, constituído por 16 frações, 7 de tipologia T3, 5 de tipologia T2 e 4 de tipologia T1, distribuídas por sub-cave, cave, rés-do-chão, 5 pisos e sótão destinado a arrumos",
        collaborative: false
      },
      {
        client: "Complexo Habitacional - Matosinhos",
        description:
          "Realização de projeto de instalações de utilização de energia eléctrica em baixa tensão e instalações de telecomunicações referentes a um complexo habitacional com área de implantação de 255m2, situado no Lugar do Freixieiro, lote 3 em Matosinhos, constituído por 11 frações, 4 de tipologia T2 e 7 de tipologia T1, distribuídas por cave, rés-do-chão e 3 pisos.",
        collaborative: false
      },
      {
        client: "Complexo Hoteleiro - Luanda",
        description:
          "Realização de projeto de instalações de utilização de energia eléctrica em baixa tensão e instalações de telecomunicações referentes a um complexo hoteleiro, Guest Office Hotel, com área de 2142m2, situado na cidade de Luanda em Angola, desenvolvendo-se por 5 pisos acima do solo e 1 subterrâneo, constituído 12 suites, 6 salas de reuniões, gabinetes, piscina, ginásio, cabeleireiro, recepção, cozinha, sala de refeições, zonas técnicas e arrumos.",
        collaborative: false
      },
      {
        client: "Moradia T4 - Oliveira de Azeméis",
        description:
          "Realização de projeto de instalações de utilização de energia eléctrica em baixa tensão, instalações de telecomunicações e instalações especiais referentes a uma moradia unifamiliar de tipologia T4, constituída por 4 quartos, 2 wc's, zona de estar/jantar, cozinha, garagem e arrumos, situada em Oliveira de Azeméis.",
        collaborative: false
      },
      {
        client: "Moradia T4 - Vale de Cambra",
        description:
          "Realização de projeto de instalações de utilização de energia eléctrica em baixa tensão, instalações de telecomunicações e instalações especiais referentes a uma moradia unifamiliar de tipologia T4, constituída por 4 suites completas, 1 wc de serviço, zona de estar, sala de jantar, jacuzzi, ginásio, cozinha, garagem e zona de arrumos, situada em São Pedro de Castelões, Vale de Cambra.",
        collaborative: false
      },
      {
        client: "Complexo Habitacional - Tavira",
        description:
          "Realização de projeto de instalações privadas de distribuição de energia eléctrica em baixa tensão ,instalações de utilização de energia eléctrica em baixa tensão, instalações de telecomunicações e arranjos exteriores referentes a um loteamento habitacional privado composto por 7 edifícios residenciais, com área de 278m2 por piso e 3 pisos, caracterizados por 4 habitações de tipologia T2 e 1 de tipologia T3, situado no Sítio de Canada em Tavira.",
        collaborative: false
      },
      {
        client: "Espaço Comercial - Albufeira",
        description:
          "Realização de projeto de instalações de utilização de energia eléctrica em baixa tensão, instalações de telecomunicações, instalações de segurança e instalação de som ambiente e chamada referentes a um espaço comercial com área aproximada de 1250m2, situado no Lugar da Tavagueira, Arm. E em Albufeira, constituído por zona de público, pós-venda, armazém, escritório, refeitório vestiários e wc's.",
        collaborative: false
      },
      {
        client: "Parque de Estacionamento Público - Tomar",
        description:
          "Realização de projeto de instalações de utilização de energia eléctrica em baixa tensão, instalações de telecomunicações e instalação de segurança referentes a um parque de estacionamento público com área de construção de 5352m2, situado na Praça da República em Tomar, constituído por 3 pisos com um total de 187 lugares de estacionamento.",
        collaborative: false
      },
      {
        client: "Espaço Comercial - Setúbal",
        description:
          "Realização de projeto de instalações de utilização de energia eléctrica em baixa tensão, instalações de telecomunicações e instalação de som ambiente e chamada referentes a um espaço comercial com área de 2008m2, situado Av. Mestre Lima Freitas, Arm. D em Setúbal, constituído por zona de público, pós-venda, armazém, escritório, refeitório vestiários e wc's.",
        collaborative: false
      },
      {
        client: "Hotel Rural - Covas do Douro",
        description:
          "Realização de projeto de instalações de utilização de energia eléctrica em baixa tensão, instalações de telecomunicações e instalação de som ambiente e chamada referentes a um hotel rural situado em Quinta Nova, Covas do Douro.",
        collaborative: false
      },
      {
        client: "Edifício Habitacional - Porto",
        description:
          "Realização de projeto de instalações de utilização de energia eléctrica em baixa tensão e instalações de telecomunicações referentes a um edifício com r/chão, 1º, 2º e 3º andar, com área de habitável de 576m2, situado na Rua Costa Cabral no Porto, constituído por estúdio e laboratório de fotografia, biblioteca, gabinetes, salas de aula, salas de trabalhos multidisciplinares, sala de reuniões, arquivo e wc's.",
        collaborative: false
      },
      {
        client: "Espaço Comercial - Ponta Delgada",
        description:
          "Realização de projeto de instalações de utilização de energia eléctrica em baixa tensão, instalações de telecomunicações, instalações de segurança e instalação de som ambiente e chamada referentes a um espaço comercial com área de 1709m2, situado na Estrada São Gonçalo em Ponta Delgada, constituído por zona de público, pós-venda, armazém, escritório, refeitório vestiários e wc's.",
        collaborative: false
      },
      {
        client: "Complexo de Armazéns - Braga",
        description:
          "Realização de projeto de instalações de utilização de energia eléctrica em baixa tensão e instalações de telecomunicações referentes a 7 fracções contíguas de armazéns, com área de 630m2 por fracção, desenvolvendo-se por 3 pisos, situado em Ferreiros, Largo da Misericórdia, Braga, constituído por zona de armazém, comércio ou serviços, wc's e aparcamento interior privativo.",
        collaborative: false
      },
      {
        client: "Cinema - Bragança",
        description:
          "Realização de projeto de instalações de utilização de energia eléctrica em baixa tensão, instalações de telecomunicações e instalações de segurança referentes a um cinema com área de 1014m2, situado no Forum Theatrum, na Praça Cavaleiro de Ferreira em Bragança, constituído por 3 salas de cinema, cabine de projecção única, bar e bilheteira, zona de escritório e wc's.",
        collaborative: false
      },
      {
        client: "Moradia T4 - Paredes",
        description:
          "Realização de projeto de instalações de utilização de energia eléctrica em baixa tensão, instalações de telecomunicações e instalações especiais referentes a uma moradia unifamiliar de tipologia T4, constituída por 4quartos, 2 wc's, zona de estar, sala de jantar, escritório, cozinha e garagem, situada em Besteirós, Paredes",
        collaborative: false
      },
      {
        client: "Espaço Comercial - Montijo",
        description:
          "Realização de projeto de instalações de utilização de energia eléctrica em baixa tensão, instalações de telecomunicações e instalação de som ambiente e chamada referentes a um espaço comercial com área de 2080m2, situado na Rua da Azinheira, Armazém D, Zona Industrial Pau Queimado no  Montijo, constituído por zona de público, pós-venda, armazém, escritório, refeitório vestiários e wc's.",
        collaborative: false
      }
    ]
  },
  {
    title: "Segurança",
    image: LeonorAvif,
    projects: [
      {
        client: "Condomínio Residencial Foz - Porto",
        image: Leonor1,
        description:
          "Empreendimento habitacional Premium na Foz do Douro, numa das zonas residenciais mais desejadas do Porto. A poucos metros do rio e a poucos passos do mar, o edifício tem acesso privilegiado a tudo o que necessita. Com apenas um apartamento por piso e vigilância permanente, este é um condomínio fechado, com amplo jardim, generosos terraços, piscinas privadas e entradas individuais para cada habitação, composto por 18 apartamentos T3 e T4, 4 penthouses duplex. Além da arquitetura contemporânea e da utilização exclusiva dos melhores materiais, o empreendimento tira o máximo partido da domótica e das tecnologias avançadas, sempre em benefício do conforto, da harmonia e da tranquilidade. Excelente exposição solar e deslumbrantes vistas de rio e mar, livres e desafogadas.",
        collaborative: false,
        images: [Leonor1, Leonor2, Leonor3, Leonor4, Leonor5]
      },
      {
        client: "Edifício de Serviços Municipais - Coimbra",
        image: ANMP1,
        description:
          "Desenvolvimento das subespecialidade de distribuição de energia, telecomunicações e segurança do novo edifício que amplia a sede da Associação, na Avenida Marnoco e Sousa, em Coimbra. Com um investimento de cerca de 400 mil euros, este novo edifício tem como objetivo a melhoria das condições de trabalho à equipa da ANMP.",
        collaborative: false,
        images: [ANMP1, ANMP2, ANMP3, ANMP4]
      },
      {
        client: "Empreendimento Habitacional e Comercial - Porto",
        image: Alameda1,
        description:
          "Inserido numa zona residencial premium, no coração das Antas, o empreendimento destaca-se por usufruir de uma localização privilegiada e de uma elevada qualidade urbanística. A edificação é de utilização mista, habitação e serviços, de formas cúbicas simples e fachada ventilada em pedra grampeada e compósito de alumínio. O piso térreo destina-se ao uso de serviços e os restantes seis pisos superiores são constituídos por 33 apartamentos de tipologia T1 a T5. Próximo de todo o tipo de comodidades e serviços, com destaque para o Alameda Shopping, a proximidade do Metro confere-lhe um valor acrescido pela facilidade de transporte público.",
        collaborative: false,
        images: [Alameda1, Alameda2, Alameda3, Alameda4]
      },
      {
        client: "Empreendimento Habitacional e Parque Público - Lisboa",
        image: Distrikt1,
        description:
          "Empreendimento habitacional de luxo, localizado em zona nobre da cidade de Lisboa, projetado com base no compromisso de criar espaços atrativos e funcionais, adaptados ao ambiente e à cidade. A adaptação da arquitetura ao ambiente e as formas dinâmicas e a escala do projeto enquadram-se perfeitamente na atmosfera futurista do Parque das Nações. Conta com ampla zona verde e de lazer, com piscinas e espaços ajardinados, numa plataforma elevada em relação à rua e vista para o rio Tejo, permitindo o desfrutar de paisagens extraordinárias.",
        collaborative: false,
        images: [Distrikt1, Distrikt2, Distrikt3, Distrikt4, Distrikt5]
      },
      {
        client: "Empreendimento Hoteleiro - Braga",
        image: Plaza1,
        description:
          "Empreendimento hoteleiro na cidade de Braga que tem como ponto de partida um edifício contíguo ao antigo Recolhimento das Convertidas, em plena Avenida Central da cidade. Com cunho português no design, decoração e materiais, o complexo hoteleiro disporá de 109 quartos, uma sala de reuniões, restaurante, bar, spa, piscina interior, piscina exterior e claustro.",
        collaborative: false,
        images: [Plaza1, Plaza2, Plaza3, Plaza4, Plaza5]
      },
      {
        client: "Empreendimento Turístico - Lagos",
        image: Hotel1,
        description:
          "Complexo turístico, na modalidade de hotel – apartamento de 5 estrelas com capacidade de alojamento de 402 camas, numa área de construção de 22.994 m2. O empreendimento, em forma de leque, caracteriza-se pela existência de 3 corpos distintos. O corpo A, a Nascente, o corpo B, considerado como o corpo central e o corpo C, a Poente. O corpo A e C contam com 3 pisos, sendo o corpo B constituido por 2 pisos. O empreendimento turístico contempla espaços de SPA, salão de jogos, restaurante panorâmico, sala de conferências, sala de formação, zonas de estar, zona de leitura, exposição de arte, 3 lojas e espaço para apoio a crianças.",
        collaborative: false,
        images: [Hotel1]
      }
    ],
    moreProjects: [
      {
      client: "Moradia T4+1 - Leça do Balio",
      description:
        "Projeto de instalações de utilização de energia eléctrica em baixa tensão, instalações de telecomunicações e instalações de segurança referentes a uma moradia unifamiliar de tipologia T4+1, com área coberta de 503m2 implantada em terreno urbano de 2072m2, constituida por 4 suites, zona de estar, sala de jantar, escritório, cozinha, sala de cinema privativa, piscina exterior, garagem e zonas técnicas, situada na Rua do Chantre, lote 12, Leça do Balio.",
      collaborative: false
    },
    {
      client: "Moradia T4+1 - Leça do Balio (2)",
      description:
        "Realização de projeto de instalações de utilização de energia eléctrica em baixa tensão, instalações de telecomunicações e instalações de segurança referentes a uma moradia unifamiliar de tipologia T4+1, com área coberta de 546m2 implantada em terreno urbano de 2597m2, constituida por 4 suites, zona de estar, sala de jantar, escritório, cozinha, sala de cinema privativa, piscina exterior, garagem e zonas técnicas, situada na Rua do Chantre, lote 10, Leça do Balio",
      collaborative: false
    },
    {
      client: "Moradia T4+1 - Leça do Balio (3)",
      description:
        "Realização de projeto de instalações de utilização de energia eléctrica em baixa tensão, instalações de telecomunicações e instalações de segurança referentes a uma moradia unifamiliar de tipologia T4+1, com área coberta de 596m2 implantada em terreno urbano de 2588m2, constituida por 4 suites, zona de estar, sala de jantar, escritório, cozinha, sala de cinema privativa, piscina exterior, garagem e zonas técnicas, situada na Rua do Chantre, lote 9, Leça do Balio.",
      collaborative: false
    },
    {
      client: "Loja de Têxteis - Matosinhos",
      description:
        "Realização de projeto de instalações de utilização de energia eléctrica em baixa tensão, instalações de telecomunicações e instalações de segurança referentes a um espaço destinado ao comércio de por grosso de têxteis, com área total de 196m2, situado na Rua Sousa Aroso, Matosinhos.",
      collaborative: false
    },
    {
      client: "Loja de Calçado - Barcelos",
      description:
        "Realização de projeto de instalações de utilização de energia eléctrica em baixa tensão, instalações de telecomunicações e instalações de segurança referentes a um espaço comercial destinado ao comércio de calçado e malas de marcas nacionais e internacionais, com 4 pisos , situado na Rua D.António Barroso, Barcelos",
      collaborative: false
    },
    {
      client: "Espaço de Restauração - Lisboa",
      description:
        "Realização de projeto de instalações de utilização de energia eléctrica em baixa tensão, instalações de telecomunicações e instalações de segurança referentes a um espaço de restauração destinado à venda de produtos de chocolate e serviços de alimentação, com área total de 260m2, situado no piso 1 do Centro Comercial Dolce Vita Tejo, Lisboa.",
      collaborative: false
    },
    {
      client: "Espaço de Escritório - Lisboa",
      description:
        "Realização de projeto de instalações de utilização de energia eléctrica em baixa tensão, instalações de telecomunicações e instalações de segurança referentes a um espaço de escritório destinado a atividades de gestão e planeamento, com área total de 348m2, situado na Rua Ivone Silva, Lisboa.",
      collaborative: false
    },
    {
      client: "Espaço de Restauração - Lisboa (2)",
      description:
        "Realização de projeto de instalações de utilização de energia eléctrica em baixa tensão, instalações de telecomunicações e instalações de segurança referentes a um espaço destinado ao comércio de por grosso de têxteis, com área total de 393m2 , situado na Avenida 24 Julho, Lisboa.",
      collaborative: false
    },
    {
      client: "Espaço de Restauração - Porto ",
      description:
        "Projeto de instalações de utilização de energia eléctrica em baixa tensão, instalações de telecomunicações e instalações de segurança referentes a um espaço de restauração destinado à venda de produtos de chocolate e serviços de alimentação, com área total de 528m2, situado na Avenida da Boavista, Porto.",
      collaborative: false
    },
    {
      client: "Loja de Têxteis - Lisboa",
      description:
        "Realização de projeto de instalações de utilização de energia eléctrica em baixa tensão, instalações de telecomunicações e instalações de segurança referentes a um espaço destinado ao comércio de por grosso de têxteis, com área total de 393m2, situado na Avenida 24 Julho, Lisboa",
      collaborative: false
    },
    {
      client: "Espaço de Escritório - Porto",
      description:
        "Realização de projeto de restauro das infraestruturas de instalações de utilização de energia eléctrica em baixa tensão e instalações de telecomunicações referentes a um espaço de escritório destinado à atividade bancária e gestão de património, com área total de 390m2.",
      collaborative: false
    },
    {
      client: "Moradia T5+1, Boavista",
      description:
        "Realização de projeto de instalações de utilização de energia eléctrica em baixa tensão, instalações de telecomunicações e instalações de segurança referentes a uma moradia unifamiliar de tipologia T5+1, com área coberta de 884m2 implantada em terreno urbano de 1146m2, constituida por 5 suites, 1 suite dupla, zona de estar, sala de jantar, escritório, cozinha, piscina interior, banho turco, sauna, sala de cinema privativa, piscina exterior, garagem e zonas técnicas, situada na Avenida do Marechal Gomes da Costa, Boavista",
      collaborative: false
    },
    {
      client: "Espaço de Ourivesaria - Porto",
      description:
        "Realização de projeto de instalações de utilização de energia eléctrica em baixa tensão, instalações de telecomunicações e instalações de segurança referentes a um espaço comercial destinado ao comércio de ourivesaria, joalharia e relojoaria, com área total de 49m2, situado no Centro Comercial Dolce Vita Porto.",
      collaborative: false
    },
    {
      client: "Cafetaria - Coimbra",
      description:
        "Realização de projeto de instalações de utilização de energia eléctrica em baixa tensão e instalações de telecomunicações referentes a um espaço de restauração destinado a serviços de cafetaria e alimentação, com área total de 15m2, situado no Centro Comercial Dolce Vita Coimbra.",
      collaborative: false
    },
    {
      client: "Complexo Habitacional - Viana do Castelo",
      description:
        "Realização de projeto de instalações de utilização de energia eléctrica em baixa tensão e instalações de telecomunicações referentes a um complexo habitacional com área de implantação de 280m2, situado no Lugar da Bessa, lote 2 em Viana do Castelo, constituído por 16 frações, 7 de tipologia T3, 5 de tipologia T2 e 4 de tipologia T1, distribuídas por sub-cave, cave, rés-do-chão, 5 pisos e sótão destinado a arrumos",
      collaborative: false
    },
    {
      client: "Complexo Habitacional - Matosinhos",
      description:
        "Realização de projeto de instalações de utilização de energia eléctrica em baixa tensão e instalações de telecomunicações referentes a um complexo habitacional com área de implantação de 255m2, situado no Lugar do Freixieiro, lote 3 em Matosinhos, constituído por 11 frações, 4 de tipologia T2 e 7 de tipologia T1, distribuídas por cave, rés-do-chão e 3 pisos.",
      collaborative: false
    },
    {
      client: "Complexo Hoteleiro - Luanda",
      description:
        "Realização de projeto de instalações de utilização de energia eléctrica em baixa tensão e instalações de telecomunicações referentes a um complexo hoteleiro, Guest Office Hotel, com área de 2142m2, situado na cidade de Luanda em Angola, desenvolvendo-se por 5 pisos acima do solo e 1 subterrâneo, constituído 12 suites, 6 salas de reuniões, gabinetes, piscina, ginásio, cabeleireiro, recepção, cozinha, sala de refeições, zonas técnicas e arrumos.",
      collaborative: false
    },
    {
      client: "Moradia T4 - Oliveira de Azeméis",
      description:
        "Realização de projeto de instalações de utilização de energia eléctrica em baixa tensão, instalações de telecomunicações e instalações especiais referentes a uma moradia unifamiliar de tipologia T4, constituída por 4 quartos, 2 wc's, zona de estar/jantar, cozinha, garagem e arrumos, situada em Oliveira de Azeméis.",
      collaborative: false
    },
    {
      client: "Moradia T4 - Vale de Cambra",
      description:
        "Realização de projeto de instalações de utilização de energia eléctrica em baixa tensão, instalações de telecomunicações e instalações especiais referentes a uma moradia unifamiliar de tipologia T4, constituída por 4 suites completas, 1 wc de serviço, zona de estar, sala de jantar, jacuzzi, ginásio, cozinha, garagem e zona de arrumos, situada em São Pedro de Castelões, Vale de Cambra.",
      collaborative: false
    },
    {
      client: "Complexo Habitacional - Tavira",
      description:
        "Realização de projeto de instalações privadas de distribuição de energia eléctrica em baixa tensão ,instalações de utilização de energia eléctrica em baixa tensão, instalações de telecomunicações e arranjos exteriores referentes a um loteamento habitacional privado composto por 7 edifícios residenciais, com área de 278m2 por piso e 3 pisos, caracterizados por 4 habitações de tipologia T2 e 1 de tipologia T3, situado no Sítio de Canada em Tavira.",
      collaborative: false
    },
    {
      client: "Espaço Comercial - Albufeira",
      description:
        "Realização de projeto de instalações de utilização de energia eléctrica em baixa tensão, instalações de telecomunicações, instalações de segurança e instalação de som ambiente e chamada referentes a um espaço comercial com área aproximada de 1250m2, situado no Lugar da Tavagueira, Arm. E em Albufeira, constituído por zona de público, pós-venda, armazém, escritório, refeitório vestiários e wc's.",
      collaborative: false
    },
    {
      client: "Parque de Estacionamento Público - Tomar",
      description:
        "Realização de projeto de instalações de utilização de energia eléctrica em baixa tensão, instalações de telecomunicações e instalação de segurança referentes a um parque de estacionamento público com área de construção de 5352m2, situado na Praça da República em Tomar, constituído por 3 pisos com um total de 187 lugares de estacionamento.",
      collaborative: false
    },
    {
      client: "Espaço Comercial - Setúbal",
      description:
        "Realização de projeto de instalações de utilização de energia eléctrica em baixa tensão, instalações de telecomunicações e instalação de som ambiente e chamada referentes a um espaço comercial com área de 2008m2, situado Av. Mestre Lima Freitas, Arm. D em Setúbal, constituído por zona de público, pós-venda, armazém, escritório, refeitório vestiários e wc's.",
      collaborative: false
    },
    {
      client: "Hotel Rural - Covas do Douro",
      description:
        "Realização de projeto de instalações de utilização de energia eléctrica em baixa tensão, instalações de telecomunicações e instalação de som ambiente e chamada referentes a um hotel rural situado em Quinta Nova, Covas do Douro.",
      collaborative: false
    },
    {
      client: "Edifício Habitacional - Porto",
      description:
        "Realização de projeto de instalações de utilização de energia eléctrica em baixa tensão e instalações de telecomunicações referentes a um edifício com r/chão, 1º, 2º e 3º andar, com área de habitável de 576m2, situado na Rua Costa Cabral no Porto, constituído por estúdio e laboratório de fotografia, biblioteca, gabinetes, salas de aula, salas de trabalhos multidisciplinares, sala de reuniões, arquivo e wc's.",
      collaborative: false
    },
    {
      client: "Espaço Comercial - Ponta Delgada",
      description:
        "Realização de projeto de instalações de utilização de energia eléctrica em baixa tensão, instalações de telecomunicações, instalações de segurança e instalação de som ambiente e chamada referentes a um espaço comercial com área de 1709m2, situado na Estrada São Gonçalo em Ponta Delgada, constituído por zona de público, pós-venda, armazém, escritório, refeitório vestiários e wc's.",
      collaborative: false
    },
    {
      client: "Complexo de Armazéns - Braga",
      description:
        "Realização de projeto de instalações de utilização de energia eléctrica em baixa tensão e instalações de telecomunicações referentes a 7 fracções contíguas de armazéns, com área de 630m2 por fracção, desenvolvendo-se por 3 pisos, situado em Ferreiros, Largo da Misericórdia, Braga, constituído por zona de armazém, comércio ou serviços, wc's e aparcamento interior privativo.",
      collaborative: false
    },
    {
      client: "Cinema - Bragança",
      description:
        "Realização de projeto de instalações de utilização de energia eléctrica em baixa tensão, instalações de telecomunicações e instalações de segurança referentes a um cinema com área de 1014m2, situado no Forum Theatrum, na Praça Cavaleiro de Ferreira em Bragança, constituído por 3 salas de cinema, cabine de projecção única, bar e bilheteira, zona de escritório e wc's.",
      collaborative: false
    },
    {
      client: "Moradia T4 - Paredes",
      description:
        "Realização de projeto de instalações de utilização de energia eléctrica em baixa tensão, instalações de telecomunicações e instalações especiais referentes a uma moradia unifamiliar de tipologia T4, constituída por 4quartos, 2 wc's, zona de estar, sala de jantar, escritório, cozinha e garagem, situada em Besteirós, Paredes",
      collaborative: false
    },
    {
      client: "Espaço Comercial - Montijo",
      description:
        "Realização de projeto de instalações de utilização de energia eléctrica em baixa tensão, instalações de telecomunicações e instalação de som ambiente e chamada referentes a um espaço comercial com área de 2080m2, situado na Rua da Azinheira, Armazém D, Zona Industrial Pau Queimado no  Montijo, constituído por zona de público, pós-venda, armazém, escritório, refeitório vestiários e wc's.",
      collaborative: false
    }
  ]
  },
  {
    title: "Instalações Especiais",
    image: MacauAvif,
    projects: [
      {
        client: "Centro Cultural - Macau",
        image: Macau1,
        description:
          "Assessoria e consultoria no âmbito do desenvolvimento de projecto de instalações cénicas, nomeadamente no dimensionamento de canalizações e determinação de potência para quadros eléctricos, sistemas de iluminação de cena, ethernet, e mecânica de cena referente a um Auditório em Macau. Realização de esquemas de caminhos de cabos, rede de iluminação cénica, rede de ethernet, instalação de mecânica de cena e revisão de caderno de encargos",
        collaborative: false,
        images: [Macau1, Macau2, Macau3, Macau4, Macau5]
      },
      {
        client: "Ilha da Cazanga Golf & Resort, Luanda",
        image: Cazanga1,
        description:
          "Realização de estudo luminotécnico e parecer técnico para a totalidade dos arruamentos (vias principais, vias secundárias e caminhos pedonais) da Ilha turística da Cazanga, situada ao largo de Luanda, entre a zona marginal da Corimba e a Península do Mussulo. O trabalho visou o estudo luminotécnico para a concretização de um Resort multiusos de qualidade e elevado padrão residencial e turístico que contempla 1 business hotel, 1 hotel-clínica, 1 SPA, 1 campo de golf de 18 buracos, 1 academia de golfe, country club, 968 vivendas sendo 247 do tipo T2, 335 do tipo T3, 268 do tipo T4 e 116 do tipo T5, uma marina com cerca de 500 amarrações, restaurantes exóticos, área comercial, piscinas oceânicas, igreja, retiro e um museu de cultura africana entre outras atracções.",
        collaborative: false,
        images: [Cazanga1, Cazanga2, Cazanga3, Cazanga4, Cazanga5]
      },
      {
        client: "Centro de Artes e Espetáculos, Portimão",
        image: CAEPIESP1,
        description:
          "Realização de projeto de instalações de utilização de energia eléctrica, incluindo posto de transformação, grupo eletrogéneo e infraestruturas de utilização de energia bem como das instalações de telecomunicações e instalações de segurança referente ao Complexo Turístico com SPA, AquaFalls. Empreendimento turístico de cinco estrela implantado numa área de construção de 26600m2 na encosta da serra virada ao Parque Natural do Gerês e para o Rio Cávado, junto à barragem da Caniçada. A edificação é constituída por um complexo central com piscina interior aquecida, piscina exterior, sauna, banho turco, fonte de gelo, duche de sensações, 6 gabinetes de terapia, espaços de relaxamento, ginásio, auditório, restaurante, bar e espaço comercial. Paralelamente ao edifício central, o empreendimento é composto por 22 suites e 2 quartos, distribuídos por 11 bangalows. No exterior, o complexo é completado pelo campo de ténis e pelo mini-golfe.",
        collaborative: false,
        images: [CAEPIESP1, CAEPIESP2, CAEPIESP3, CAEPIESP4, CAEPIESP5]
      },
      {
        client: "Centro Cultural e de Congressos, Caldas da Raínha",
        image: CCCR1,
        description:
          "Realização de projeto de instalações eléctricas especiais (instalações cénicas) referente ao Complexo Multiusos das Cladas da Rainha. Situado na Rua Doutor Leonel Sotto Mayor, Caldas da Rainha, é constituído por um grande auditório, com fosso de orquestra (aproveitamento com 60 lugares), 1ª plateia (140 lugares), 2ª plateia (200 lugares incluindo 6 lugares para pessoas de mobilidade reduzida), tribuna (100 lugares) e 16 camarotes laterais (16 x 10 lugares cada), num total de 660 lugares, um pequeno auditório com 150 lugares, camarins e bastidores, café-concerto, sala de exposições, salas de apoio, divisões administrativas e estacionamento para 350 viaturas, desenvolvendo-se por 6 pisos. Instalações e equipamentos de iluminação e comando de cena (DMX). Instalações e equipamentos de cinema e vídeo",
        collaborative: false,
        images: [CCCR1, CCCR2, CCCR3, CCCR4, CCCR5]
      }
    ],
    moreProjects: []
  },
  {
    title: "Outras Especialidades",
    image: Estabilidade,
    projects: [
      {
        client: "Igemaci Engenharia, SA - Loteamento Soyo",
        image: Image4,
        description:
          "Distribuição de energia eléctrica em baixa tensão referente a um loteamento, Soyo II, com área total de 565 hectares divididos por 11 unidades. Foram elaboradas as unidades 9 (9a e 9b) e 10 (10a e 10b) com 105 hectares cada. Situado na cidade de Soyo no norte de Angola, o loteamento conta com zonas de uso residencial, profissional, hospitalares, parques infantis, e desportivas",
        collaborative: false,
        images: [Image4, Image5, Image6]
      }
    ],
    moreProjects: []
  }
];
