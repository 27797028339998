import React, { useState } from "react";
import { useSwipeable } from 'react-swipeable';
import "./DetailedProjectModal.css";

function DetailedProjectModal({ project, onClose }) {


  

  const [currentImageIndex, setCurrentImageIndex] = useState(0);


  const handleSwipedLeft = (eventData) => {
    eventData.event.stopPropagation();
    setCurrentImageIndex(currentImageIndex + 1 >= project.images.length ? currentImageIndex : currentImageIndex + 1);
  };

  const handleSwipedRight = (eventData) => {
    eventData.event.stopPropagation();
    setCurrentImageIndex(currentImageIndex - 1 < 0 ? 0 : currentImageIndex - 1);
  };

  const handlers = useSwipeable({
    onSwipedLeft: handleSwipedLeft,
    onSwipedRight: handleSwipedRight,
    preventDefaultTouchmoveEvent: true,
    trackMouse: true
  });

  return (
    <div className="detailedProjectModal">
      <button className="closeButton" onClick={onClose}>
        ×
      </button>
      <h2>{project.client}</h2>

      <div className="imageGallery" {...handlers}>
        <button
          className="arrowButton previous"
          onClick={() => setCurrentImageIndex((prev) => prev - 1)}
          disabled={currentImageIndex === 0}
        >
          ←
        </button>
        <img
          src={project.images[currentImageIndex]}
          alt={`Imagem ${currentImageIndex + 1}`}
        />
        <div className="dotIndicators">
          {project.images.map((_, index) => (
            <span
              key={index}
              className={`dot ${
                currentImageIndex === index ? "activeDot" : ""
              }`}
              onClick={() => setCurrentImageIndex(index)}
            ></span>
          ))}
        </div>
        <button
          className="arrowButton next"
          onClick={() => setCurrentImageIndex((prev) => prev + 1)}
          disabled={currentImageIndex === project.images.length - 1}
        >
          →
        </button>
      </div>

      <div className="descriptionBox">{project.description}</div>
      <p>{project.collaborative ? "Feito em colaboração." : ""}</p>
    </div>
  );
}

export default DetailedProjectModal;
