import React, {useEffect} from "react";
import Navbar from "../components/Navbar";
import Hero from "../components/Hero";
import AboutImg from "../assets/abouus.jpg";
import Footer from "../components/Footer";
import TeamImg from "../assets/imagemAbout";
import Team from "../components/Team";
import AboutUs from "../components/AboutUs";
import RestTeam from "../components/RestTeam";

function About() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <>
      <Navbar />
      <Hero
        cName="hero-mid"
        heroImg={AboutImg}
        title="Sobre Nós"
        titleClass="page-title"
        btnClass="hide"
      />
      <AboutUs />
      <Team />
      <RestTeam />
      <Footer />
    </>
  );
}

export default About;
