import React from "react";
import "./PortfolioIntroduction.css";

const PortfolioIntroduction = () => {
  return (
    <section className="portfolioIntroSection">
      <div className="container">
        <p>
          Na Cubic Choices, Lda, a inovação e qualidade técnica andam de mãos
          dadas. O nosso portfólio é um reflexo do nosso compromisso com a
          excelência em engenharia eletrotécnica, abrangendo uma ampla gama de
          projetos que destacam nossa capacidade de adaptação, precisão e
          criatividade.
        </p>
        <p>
          Cada projeto é uma jornada de descoberta, desafio e sucesso.
          Convidámo-lo a explorar os nossos trabalhos mais relevantes, mostrando como transformamos os desafios em soluções inovadoras e
          sustentáveis.
        </p>
      </div>
    </section>
  );
};

export default PortfolioIntroduction;
