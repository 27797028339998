import Navbar from "../components/Navbar";
import React, { useEffect } from 'react';
import Hero from "../components/Hero";
import Footer from "../components/Footer";
import Doings from "../components/Doings";
import mobileHeroImage from '../assets/static.JPG'; // Adjust the path as necessary


function Home() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <>
      <Navbar />
      <Hero
  cName="hero"
  title="Engineering the Future"
  titleClass="home-title" // Add this property
  buttonText="Visite o nosso Portfólio!"
  url="/service"
  btnClass="show"
  isVideo={true}
  mobileImageSrc={mobileHeroImage} // Pass this prop only in Home

/>
      <Doings />
      <Footer />
    </>
  );
}

export default Home;
