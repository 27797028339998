import React from "react";
import "./AboutUs.css";

const AboutUs = () => {
  return (
    <section className="aboutUsSection">
      <div className="container">
        <p>
          A Cubic Choices, Lda tem o compromisso da crescente qualidade dos
          serviços prestados e a capacidade de domínio e inovação nas diversas
          área de engenharia onde se insere. Prestamos serviços personalizados
          para cada projeto que desenvolvemos, à medida do cliente.
        </p>
        <h2>O que nos define:</h2>
        <p>
          <i className="fas fa-cogs fa-lg icon"></i>
          <strong>Excelência Técnica:</strong> Contamos com profissionais
          experientes e qualificados, garantindo que cada projeto seja executado
          com alto nível de profissionalismo e rigor técnico.
        </p>
        <p>
          <i className="fas fa-leaf fa-lg icon"></i>
          <strong>Compromisso com a Sustentabilidade:</strong> Estamos
          comprometidos com a utilização de práticas sustentáveis em todas as
          nossas instalações, garantindo não apenas eficiência energética, mas
          também um impacto ambiental positivo.
        </p>
        <p>
          <i className="fas fa-lightbulb fa-lg icon"></i>
          <strong>Inovação Contínua:</strong> Equipa sempre atualizada com a
          evolução tecnológica e legislação aplicada às nossas áreas de
          trabalho. Tal nos permite oferecer soluções de ponta, eficientes e
          sustentáveis, mantendo os mais altos padrões de segurança.
        </p>
        <p>
          <i className="fas fa-user-friends fa-lg icon"></i>
          <strong>Atendimento ao Cliente Excepcional:</strong> A satisfação do
          cliente é nossa prioridade. Trabalhamos em estreita colaboração com os
          nossos clientes para entender as suas necessidades e entregar soluções
          personalizadas que, se possível, superem as suas expectativas.
        </p>
      </div>
    </section>
  );
};

export default AboutUs;
