import Navbar from "../components/Navbar";
import React, { useEffect } from 'react';
import Hero from "../components/Hero";
import AboutImg from "../assets/portfolio333.png";
import Footer from "../components/Footer";
import PortfolioC from "../components/PortfolioC";
import PortfolioIntroduction from "../components/PortfolioIntroduction";

function Portfolio() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <>
      <Navbar />
      <Hero
        cName="hero-mid"
        heroImg={AboutImg}
        title="Portfólio"
        titleClass="page-title"
        btnClass="hide"
      />
      <PortfolioIntroduction />
      <PortfolioC />
      <Footer />
    </>
  );
}

export default Portfolio;
