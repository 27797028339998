import React, { useState } from "react";
import ProjectCard from "./ProjectCard";
import TopicModal from "./TopicModal";
import MoreProjectsModal from "./MoreProjectsModal";
import "./PortfolioC.css";
import TopicDescription from "./TopicDescription";

import { topics } from "./topicsData";

function PortfolioC() {
  const [selectedTopic, setSelectedTopic] = useState(null);
  const [showMoreProjects, setShowMoreProjects] = useState(false);

  // Função para ordenar os projetos por ordem alfabética
  const sortProjectsAlphabetically = (projects) => {
    return projects.sort((a, b) => a.client.localeCompare(b.client));
  };

  // Função para ordenar os moreProjects por ordem alfabética
  const sortMoreProjectsAlphabetically = (moreProjects) => {
    return moreProjects.sort((a, b) => a.client.localeCompare(b.client));
  };

  return (
    <div className="portfolio">
      {topics.map((topic) => (
        <ProjectCard
          key={topic.title}
          topic={{
            ...topic,
            projects: topic.projects ? sortProjectsAlphabetically(topic.projects) : []
          }}
          onSelect={setSelectedTopic}
        />
      ))}

{selectedTopic && 
          <TopicModal
            topic={{
              ...selectedTopic,
              projects: selectedTopic.projects ? sortProjectsAlphabetically(selectedTopic.projects) : []
            }}
            onClose={() => setSelectedTopic(null)}
            onMoreProjects={() => setShowMoreProjects(true)}
          />
        
      }

     
    </div>
  );
}

export default PortfolioC;
