import React from "react";
import "./RestTeam.css";

function RestTeam() {
  return (
    <section className="restTeamSection">
      <div className="container">
        <h1>A EQUIPA</h1>
        <p>
          Contamos com um grupo de engenheiros, com vasta experiência em
          diversas áreas de engenharia, que trazem know-how técnico a cada
          projeto que desenvolvemos. 
        </p>
        <p>
          Os nossos profissionais são conhecidos pela sua
          capacidade de abordar os desafios mais complexos, trabalhando em
          harmonia e combinando as suas aptidões profissionais, tudo no sentido
          de proporcionar soluções técnicas excepcionais que, se possível,
          superem as expectativas dos clientes.
        </p>
        <p>
          A excelência da nossa equipa tem sido a chave para o crescimento da
          Cubic Choices, Lda.
        </p>
      </div>
    </section>
  );
}

export default RestTeam;
