import React from 'react';
import { FaLinkedin } from 'react-icons/fa';
import './FooterStyles.css';
import linkedinIcon from '../assets/linkedin.png'; // Ajuste o caminho conforme necessário


const Footer = () => {
  const linkedInUrl = "https://www.linkedin.com/in/seu-perfil/"; // Substitua pela URL do perfil do LinkedIn

    return (
        <footer className="footer text-center text-lg-start text-white">

            {/* Social Media Section */}
            <section className="footer-social-media">
                <div className="container">
                    <div className="row">
                        <div className="col-md-6 text-left">
                            <span>Encontra-nos no LinkedIn:</span>
                        </div>
                        <div className="col text-right">
                            <a href={linkedInUrl} target="_blank" rel="noopener noreferrer">
                                <img src={linkedinIcon} alt="LinkedIn" className="social-icon" />
                            </a>
                        </div>
                    </div>
                </div>
            </section>

            {/* Links and Info Section */}
            <section className="footer-content">
                <div className="container">
                    <div className="row">
                        {/* Company Name Column */}
                        <div className="col-md-3 col-lg-4 col-xl-5 mx-auto ">
                            <h6 className="text-uppercase fw-bold">Cubic Choices</h6>
                            <p>A Cubic Choices, Lda tem o compromisso da crescente qualidade dos serviços prestados e a capacidade de domínio e inovação nas diversas área de engenharia onde se insere.</p>
                        </div>

                        {/* Contact Column */}
                        <div className="col-md-4 col-lg-3 col-xl-3 mx-auto mb-md-0 mb-1">
                            <h6 className="text-uppercase fw-bold">Contactos</h6>
                            <p><i className="fas fa-envelope mr-3"></i> cubic.choices@gmail.com</p>
                            <p><i className="fas fa-phone mr-3"></i> 933425475 / 936952484</p>
                        </div>
                    </div>
                </div>
            </section>

            {/* Copyright */}
            <div className="footer-bottom">
                © 2023 Copyright: Cubic Choices
            </div>

        </footer>
    );
}

export default Footer;