import "./DoingsStyles.css";
import DoingsData from "./DoingsData";
import Doings1 from "../assets/projetosAltaTensao";
import Doings2 from "../assets/projetosCentrais";
import Doings3 from "../assets/projetosBaixaTensao";
import Doings4 from "../assets/safety.avif";
import Doings5 from "../assets/projetosTele";
import Doings6 from "../assets/projetosOutras";

function Doings() {
  return (
    <div className="doings">
      <h1>OS NOSSOS SERVIÇOS </h1>
      <h6>
        {" "}
        Na vanguarda da inovação em engenharia, oferecemos soluções precisas e
        eficientes para impulsionar os seus projetos.
      </h6>
      <div className="doingscard">
        <DoingsData
          image={Doings1}
          heading="Projetos de Linhas de Média e Alta Tensão"
          text="Entendemos a complexidade e a responsabilidade inerentes às redes de distribuição.
          "
        />

        <DoingsData
          image={Doings2}
          heading="Projetos de Centrais Solares      
          "
          text="Desenvolvemos projetos de centrais solares (UPP, UPAC e CER) de alta eficiência que ajudam os nossos clientes a adotar fontes de energia renovável e economizar custos a longo prazo"
        />

        <DoingsData
          image={Doings3}
          heading="Projetos de Instalações de Baixa Tensão"
          text="Oferecemos soluções personalizadas para instalações de baixa tensão, abrangendo desde sistemas de iluminação e distribuição de energia até segurança elétrica.
          "
        />
        <DoingsData
          image={Doings4}
          heading="Projetos de Segurança"
          text="Com sólida experiência e reconhecimento no desenvolvimento de projetos de sistemas de segurança em edifícios, garantimos soluções eficazes e tecnológicas para proteção integral de instalações e pessoas.
          "
        />
        <DoingsData
          image={Doings5}
          heading="Projetos de Infraestruturas de Telecomunicações"
          text="Desenvolvemos infraestruturas de telecomunicações de ITED e ITUR em conformidade com as normas técnicas aplicáveis, garantindo conectividade e desempenho de qualidade."
        />
        <DoingsData
          image={Doings6}
          heading="Projetos de Outras Especialidades"
          text="Com base nos consórcio que desenvolvemos, damos resposta a todas as áreas de engenharia, minimizando preocupações com contratações de serviços por parte dos nossos clientes. Somos versáteis e capazes de abordar projetos desafiantes em diversas disciplinas da engenharia."
        />
      </div>
    </div>
  );
}

export default Doings;
