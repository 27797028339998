import React from "react";
import "./MoreProjectsDetailsModal.css";

function MoreProjectsDetailsModal({ project, onClose }) {
  return (
    <div className="detailsBackdrop" onClick={onClose}>
      <div className="projectDetailsModal" onClick={(e) => e.stopPropagation()}>
        <button className="detailsCloseButton" onClick={onClose}>
          ×
        </button>
        <h3>{project.client}</h3>
        <div className="descriptionBox">{project.description}</div>
        
        {
       /* <div className="collaborativeInfo">
          {project.collaborative ? "Feito em colaboração." : ""}
          </div> */
        }
        
      </div>
    </div>
  );
}

export default MoreProjectsDetailsModal;
