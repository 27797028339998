import React, { useState, useEffect } from "react";
import DetailedProjectModal from "./DetailedProjectModal";
import MoreProjectsModal from "./MoreProjectsModal"; // Import the modal
import "./TopicModal.css";
import Macau2 from "../assets/Estabilidade1.avif";
import Macau3 from "../assets/ContencaoPeriferica1.jpg";
import Macau4 from "../assets/Hidraulicas1.avif";
import Macau5 from "../assets/extintores.jpg";
import Arcadia1 from "../assets/aquecimento.avif";
import Arcadia2 from "../assets/gas.avif";
import Arcadia3 from "../assets/temperatura.avif";
import Arcadia4 from "../assets/REH.jpeg";
import Arcadia5 from "../assets/acustic.avif";

function TopicModal({ topic, onClose, onMoreProjects }) {


  useEffect(() => {
    // Disable scrolling on the main page
    const originalOverflow = document.body.style.overflow;
    document.body.style.overflow = 'hidden';

    return () => {
      // Re-enable scrolling when the modal closes
      document.body.style.overflow = originalOverflow;
    };
  }, []);

  const [selectedProject, setSelectedProject] = useState(null);
  const [showMoreProjects, setShowMoreProjects] = useState(false);

  // Condição para verificar se é o tópico "Outras Especialidades"
  const isOtherSpecialties = topic.title === "Outras Especialidades";

  const specialties = [
    { name: "Projeto de Estabilidade", image: Macau2 },
    { name: "Projeto de Contenção Periférica", image: Macau3 },
    { name: "Projeto de Instalações Hidráulicas", image: Macau4 },
    { name: "Projeto de Segurança Contra Incêndios em Edifícios (SCIE)", image: Macau5 },
    { name: "Projeto de Instalações Mecânicas de Aquecimento, Ventilação e Ar Condicionado", image: Arcadia1 },
    { name: "Projeto de Instalação Interna de Gás", image: Arcadia2 },
    { name: "Projeto de Comportamento Térmico", image: Arcadia3 },
    { name: "Certificação Energética REH", image: Arcadia4 },
    { name: "Projeto de Acústico", image: Arcadia5 },
    
  ];

  const topicsWithoutMoreProjectsButton = [
    "Outras Especialidades",
    "Centrais de Produção",
    "Instalações Especiais"
  ];
  
  const showMoreProjectsButton = !topicsWithoutMoreProjectsButton.includes(topic.title);


  // Calcula o número de colunas com base no número de projetos
  const numProjects = topic.projects.length;
  let numColumns = numProjects % 3 === 0 ? 3 : 2; // 3 colunas se divisível por 3, senão 2
  let minWidth = "280px"; // Largura mínima padrão

  if (numProjects === 1) {
    numColumns = 1; // Apenas uma coluna para um projeto
    minWidth = "80%"; // Largura mínima maior para 1 projeto
  } else if (numProjects === 2) {
    minWidth = "40%"; // Largura mínima maior para 2 projetos
  } else if (numProjects === 4) {
    minWidth = "30%"; // Largura mínima média para 4 projetos
  } else if (numProjects === 6) {
    minWidth = "20%"; // Largura mínima menor para 6 projetos
  }

  return (

    
    <div className="topicModal">
      <h2>{topic.title}</h2>
      <button className="closeButton" onClick={onClose}>&times;</button>

      {isOtherSpecialties ? (
        // Se for "Outras Especialidades", renderize os cards especializados
        <div className="gridContainer">
          {specialties.map((specialty) => (
            <div className="specialtyCard" style={{ backgroundImage: `url(${specialty.image})` }}>
              <div className="specialtyCardTitle">{specialty.name}</div>
            </div>
          ))}
        </div>
      ) : (
        // Se não, renderize os projetos padrões
        <div
          className="projectsContainer"
          style={{ gridTemplateColumns: `repeat(${numColumns}, minmax(${minWidth}, 1fr))` }}
        >
          {topic.projects.map((project) => (
            <div
              key={project.client}
              className="projectPreview"
              onClick={() => setSelectedProject(project)}
            >
                <img src={project.image} alt={project.client} className="projectImage" />
                <div className="projectNameOverlay">{project.client}</div>
              </div>
          ))}
        </div>
      )}

      {/* Renderiza o botão "Mais projetos" apenas se showMoreProjectsButton for verdadeiro */}
      {showMoreProjectsButton && (
        <button
          className="moreProjectsButton"
          onClick={() => setShowMoreProjects(true)}
        >
          Mais projetos
        </button>
      )}

      {showMoreProjects && (
        <MoreProjectsModal
          projects={topic.moreProjects}
          onClose={() => setShowMoreProjects(false)}
        />
      )}

      {selectedProject && (
        <DetailedProjectModal
          project={selectedProject}
          onClose={() => setSelectedProject(null)}
        />
      )}
    </div>
  );
}

export default TopicModal;
