import React, { useRef, useState, forwardRef } from "react";
import emailjs from "@emailjs/browser";
import "./SendMessage.css";

export const SendMessage = forwardRef((props, ref) => {
  const form = useRef();
  const [showSuccessMessage, setShowSuccessMessage] = useState(false);
  const [formError, setFormError] = useState(null);

  const sendEmail = (e) => {
    e.preventDefault();

    const name = form.current.user_name.value.trim();
    const message = form.current.message.value.trim();
    const email = form.current.user_email.value.trim();

    if (!name) {
      setFormError("Por favor, insira o seu nome.");
      return;
    } else if (!email) {
      setFormError("Por favor, insira o seu email.");
      return;
    } else if (!message) {
      setFormError("Por favor, insira a sua mensagem.");
      return;
    } else {
      setFormError(null);
    }

    emailjs
      .sendForm(
        "service_wxh6swe",
        "template_t3ywmhs",
        form.current,
        "eGwO2Bs60GT7YpfAJ"
      )
      .then(
        (result) => {
          console.log(result.text);
          setShowSuccessMessage(true);
        },
        (error) => {
          console.log(error.text);
          setFormError(
            "Ocorreu um problema ao enviar a sua mensagem. Por favor, tente mais tarde."
          );
        }
      );

    form.current.reset();
  };

  const handleNameChange = () => {
    setFormError(null);
  };

  return (
    <div ref={ref} className="send-message-container">
      <h2 className="send-message-header">Ou envie-nos a sua mensagem!</h2>
      {showSuccessMessage && (
        <div className="success-message slide-from-top">
          Mensagem enviada com sucesso!
        </div>
      )}
      {formError && (
        <div className="error-message slide-from-bottom">{formError}</div>
      )}
      <form ref={form} onSubmit={sendEmail}>
        <label>Nome</label>
        <input type="text" name="user_name" onChange={handleNameChange} />
        <label>Email</label>
        <input type="email" name="user_email" />
        <label>Mensagem</label>
        <textarea name="message" />
        <input type="submit" value="Enviar" />
      </form>
    </div>
  );
});
