import React from "react";
import { FaEnvelope, FaPhone } from "react-icons/fa";
import "./ContactInfo.css";

export const ContactInfo = ({ scrollToRef }) => {
  const handleEmailClick = () => {
    window.location.href = "mailto:cubic.choices@gmail.com";
  };

  const handleTitleClick = () => {
    if (scrollToRef && scrollToRef.current) {
      scrollToRef.current.scrollIntoView({ behavior: 'smooth' });
    }
  };

  return (
    <>
      <div className="contact-info-container">
        <h2 onClick={handleTitleClick}>Entre em contato conosco!</h2>
        <p>
          <FaEnvelope onClick={handleEmailClick} /> <a onClick={handleEmailClick} >cubic.choices@gmail.com</a> 
        </p>
        <p>
          <FaPhone /> 933425475 / 936952484
        </p>
      </div>
    </>
  );
};
