import React, { useState } from "react";
import MoreProjectsDetailsModal from "./MoreProjectsDetailsModal"; // Importe o modal
import "./MoreProjectsModal.css";

function MoreProjectsModal({ onClose, projects = [] }) {
  const [selectedProject, setSelectedProject] = useState(null);
  const [showDetailsModal, setShowDetailsModal] = useState(false);

  // Ordenar projects alfabeticamente antes de renderizar
  const sortedProjects = [...projects].sort((a, b) =>
    a.client.localeCompare(b.client)
  );

  return (
    <div className="moreProjectsModal">
      <button className="moreProjectsModalCloseButton" onClick={onClose}>
        ✕
      </button>

      {sortedProjects.map((project) => (
        <div
          key={project.client}
          className="projectListItem"
          onClick={() => {
            setSelectedProject(project);
            setShowDetailsModal(true); // Mostra o modal de detalhes quando um projeto é clicado
          }}
        >
          {project.client}
        </div>
      ))}

      {showDetailsModal && (
        <MoreProjectsDetailsModal
          project={selectedProject}
          onClose={() => setShowDetailsModal(false)}
        />
      )}
    </div>
  );
}

export default MoreProjectsModal;
