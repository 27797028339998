import React from "react";
import "./ProjectCard.css";

function ProjectCard({ topic, onSelect }) {
  return (
    <div className="projectCard" onClick={() => onSelect(topic)}>
      <div className="cardImage" style={{ backgroundImage: `url(${topic.image})` }}>
        <h3 className="cardTitle">{topic.title}</h3>
      </div>
    </div>
  );
}

export default ProjectCard;