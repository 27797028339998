import React from "react";
import "./TopicDescription.css"; // Você precisará criar este arquivo CSS

function TopicDescription({ description }) {
  return (
    <div className="topicDescription">
      {description.map((item, index) => (
        <p key={index}>{item}</p>
      ))}
    </div>
  );
}

export default TopicDescription;